<template>
  <div class="card card--t3">
    <div class="card__wrapper">
      <div class="card__body">
        <h5 class="tit fw-600 fc-dark ts-18 mb-3" v-html="data.tit" />
        <p class="txt fc-dark ts-14" v-html="data.txt" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    data () {
      return this.$parent.data
    }
  }
}
</script>
