<template>
  <component :is="comp">
    <slot></slot>
  </component>
</template>
<style lang="scss">
  @import '../assets/styles/components/card';
</style>
<script>
import t1 from '@/components/card/CardT1.vue'
import t2 from '@/components/card/CardT2.vue'
import t3 from '@/components/card/CardT3.vue'
import t4 from '@/components/card/CardT4.vue'
import t5 from '@/components/card/CardT5.vue'
import t6 from '@/components/card/CardT6.vue'
import t7 from '@/components/card/CardT7.vue'
import t8 from '@/components/card/CardT8.vue'
export default {
  props: {
    comp: {
      required: false,
      default: 1
    },
    data: {
      required: true,
      type: Object
    }
  },
  components: {
    t1, t2, t3, t4, t5, t6, t7, t8
  }
}
</script>
